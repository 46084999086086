.positive {
  font-size: 18px;
  color: rgba(11, 137, 92, 1);
}

.negative {
  font-size: 18px;
  color: rgba(255, 136, 102, 1);
}

.score {
  font-size: 18px;
  font-weight: 600;
  color: rgba(51, 68, 153, 1);
}

.slider-container {
  position: relative;
  width: 70%;
  height: 24px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: linear-gradient(to right,
      rgba(11, 137, 92, 1) 0%,

      rgba(11, 137, 92, 1) 50%,

      #cfd8dc 50%,
      /* Start color of the unfilled portion (gray) */
      #cfd8dc 100%
      /* End color of the unfilled portion (gray) */
    );
  outline: none;
  border-radius: 4px;
  margin: 0;
  transition: background 0.3s ease;
  /* Smooth transition */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: rgba(11, 137, 92, 1);

  border-radius: 50%;

  cursor: pointer;
  position: relative;
  z-index: 2;
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background-color: rgba(11, 137, 92, 1);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.thumb-label {
  position: absolute;
  top: -3px;
  transform: translateX(-50%);
  background: rgba(11, 137, 92, 1);
  color: white;
  padding: 5px 8px;
  border-radius: 50%;
  font-size: 14px;
  white-space: nowrap;
  z-index: 2;
}

.horizontal-slider {
  width: 100%;
  height: 25px;
}

.example-thumb {
  height: 30px;
  width: 30px;
  /* background-color: rgba(11, 137, 92, 1); */
  border-radius: 50%;
  cursor: pointer;
  bottom: 6px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.thumb-high-score {
  background-color: rgba(11, 137, 92, 1);
}

.thumb-medium-score {
  background-color: #77BBFF
}

.thumb-low-score {
  background-color: #FF8866
}

.track-high-score {
  background-color: rgba(11, 137, 92, 1);
  height: 10px;
  border-radius: 5px;
}

.track-low-score {
  background-color: #FF8866;
  height: 10px;
  border-radius: 5px;

}

.track-medium-score {
  background-color: #77BBFF;
  height: 10px;
  border-radius: 5px;
}

.track-empty {
  background-color: #ddd;
  height: 10px;
  border-radius: 5px;
}