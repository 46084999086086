.sidebar {
  background-color: #eeee77;
  width: 300px;
  height: 100vh;
}

.bg_yellow {
  background-color: #eeee77;
}

.text_yellow {
  color: #eeee77 !important;
  font-weight: 300 !important;
}

.topbar_main_div {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(23, 23, 23, 0.15);
  /* Adjust as needed */
  color: #fff;
  /* Adjust text color as needed */
  z-index: 1000;
  height: 60px;
  /* Ensure it appears above other content */
  /* Add any other styles you need */
}


.nav-link.active {
  /* Your CSS styles here */
  /* For example: */
  background-color: #fff !important;
  border-radius: 0;
  border: none !important;
  border-bottom: 2px solid #17171c !important;
  color: white;
}

.small_side_tabs {
  display: none;

}

.big_side_tabs {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.nav-link {
  border: none !important;
  border-radius: 0 !important;
  color: #17171c;
}

.select {
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .empty {
    margin-left: 212px;
  }
}

@media screen and (max-width: 650px) {
  .me {
    margin-left: 220px;
  }
}

@media screen and (max-width: 575px) {
  .me {
    margin-left: 52px;
  }
}

@media only screen and (max-width: 576px) {
  .logo_swirl {
    display: none !important;
  }

  .logo_swirl_small {
    display: block !important;
  }
}

.logo_swirl_small {
  margin: 0 auto;
}