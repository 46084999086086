.login_page_col_left {
  height: 100vh;
  width: 50%;
  background: url(../assets/images/login_img.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

.login_page_col_left .left_layer {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.bottom_text {
  margin-top: -30px;
}

.welcome_text {
  font-weight: bold;
  font-size: 48px;
}

.login_btn {
  border-radius: 100px;
  background-color: rgba(23, 23, 28, 0.2);
  border: none;
  color: #000;
  padding: 16px 0;
}

.login_btn:disabled {
  background-color: rgba(23, 23, 28, 1);
  color: #fff;
  padding: 16px, 202px, 16px, 202px;
}

.login_btn:hover {
  background-color: rgba(23, 23, 28, 1);
  color: #fff;
  padding: 16px, 202px, 16px, 202px;
}

.login_box {
  display: grid;
  place-items: center;
  height: 100vh;
}

.login_page_col_right {
  height: 100vh;
  width: 50%;
  background-color: #fff;
}

.row {
  padding: 0;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.underline_bottom_text {
  height: 5px;
  width: 250px;
  background-color: #fff;
  margin: auto;
  margin-top: 30px;
}

.login-placeholder::placeholder {
  opacity: 0.7;
}

@media only screen and (max-width: 1024px) {
  .login_page_col_left {
    display: none;
  }

  .login_page_col_right {
    width: 100%;
  }
}
