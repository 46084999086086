/* .switch-lg {
    width: 80px;
    height: 40px;
}

.switch-lg:checked {
    background-color: #4CAF50;
}

.switch-lg:checked::before {
    transform: translateX(40px);
} */

.product_title_preview {
  width: calc(100% - 23%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product {
  font-size: 32px;
  font-weight: 700;
}

.dropdown-toggle::after {
  display: none;
}

#responsive-tabs-product .nav-item .nav-link:not(.active) {
  color: rgba(69, 71, 69, 0.6);
  font-weight: 400;
}

#responsive-tabs-product .nav-item .nav-link.active {
  font-weight: 600;
}

.label-product {
  font-size: 14px;
}

.no-space-group .form-control {
  padding-left: 0;
  border: none;
}

.no-space-group .form-control::placeholder {
  margin-left: 0;
}
