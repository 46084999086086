/* ReviewModal.css */
.review-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.circle-badge {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  margin-left: auto;
}

.bg-dynamic {
  background-color: var(--bg-color2) !important;
}

.text-dynamic {
  color: var(--text-color2) !important;
}

@keyframes slideDown {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.review-modal-content {
  background: white;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  width: 100%;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  position: absolute;
  bottom: 0;
  height: 84vh;
}

.review-modal-content.slide-down {
  animation-name: slideDown;
}

.review-modal-content.slide-up {
  animation-name: slideUp;
}

.review-modal-close {
  position: absolute;
  top: -7%;
  right: 10px;
  background: none;
  border: none;
  /* background-color: white;
    border-radius: 50%; */
  cursor: pointer;
}

.review-modal-footer {
  margin-top: 20px;
  text-align: right;
}

.review-font {
  font-size: 14px;
  color: rgba(69, 69, 73, 1);
}

/* In your CSS file or style section */
.fa-star {
  color: gold;
  /* Color for filled stars */
}

.fa-star-o {
  color: gray;
  /* Color for empty stars */
}

.sentiment_button {
  background-color: rgba(11, 137, 92, 1);
  padding: 12px, 18px, 12px, 18px;
  color: white;
  border-radius: 5px;
}

/* .btn-primary {
    background-color: rgba(11, 137, 92, 1);
    border: none;
} */

.sentiment_button:hover {
  background-color: rgba(11, 137, 92, 1);
}

.sentiment_button2:hover {
  background-color: rgba(255, 136, 102, 1);
}

.sentiment_button2 {
  background-color: rgba(255, 136, 102, 1);
  padding: 12px, 18px, 12px;
  color: white;
}

.review-container {
  display: flex;
  justify-content: space-between;
  /* Space between items */
  align-items: center;
  /* Vertically center items */
}

.review-date {
  font-size: 14px;
  /* Adjust font size as needed */
  color: gray;
  /* Optional: Style the date text */
}

.review-container2 {
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  align-items: center;
  /* Center items horizontally */
}

.review-date2 {
  margin-top: 8px;
  /* Space between rating and date */
  font-size: 14px;
  /* Adjust font size as needed */
  color: gray;
  /* Optional: Style the date text */
}

.review-moderate-title {
  background: linear-gradient(
    180deg,
    rgba(42, 41, 41, 0) -27.47%,
    rgba(42, 41, 41, 0.22) 16.38%,
    #000000 84.5%
  );
  position: absolute;
  bottom: 7px;
  width: 23.2vw;
  padding: 18px 20px;
  border-radius: 0 0 20px 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-moderator {
  border-radius: 20px;
  margin: 2px;
}

.text {
  text-transform: capitalize;
}

.btn-primary {
  --bs-btn-bg: rgba(11, 137, 92, 1);
  --bs-btn-border-color: rgba(11, 137, 92, 1);
  --bs-btn-hover-bg: rgba(11, 137, 92, 1);
  --bs-btn-hover-border-color: rgba(11, 137, 92, 1);
}

/* .input-group {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid rgba(209, 209, 210, 1);
    border-radius: 8px;
}

.input-group .form-control {
    border-radius: 0.25rem;
    border: none;
}

.input-group-text {
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-left: -1px;
   
}

*/
/* .input-group-text .ri-file-copy-line {
    font-size: 1.3rem;


} */

.label {
  font-size: 14px;
  color: rgba(69, 69, 73, 1);
}

.hover-container {
  position: relative;
  display: inline-block;
}

.hover-content {
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
}

.hover-container:hover .hover-content {
  opacity: 1;
  /* Show the play/pause button on hover */
}
.custom-accordion .accordion-item {
  border: none; 
  --bs-accordion-btn-focus-box-shadow:none;
  --bs-accordion-active-bg: none;
}
.ck-powered-by{
  display: none !important  ;
}

.ck-file-dialog-button{
  display: none !important;
}

.custom-accordion .accordion-header {
  border: none;
  border-bottom: 1px solid rgba(209, 209, 210, 1); /* Remove border from accordion header */
}
.titles-uploadvideo{
  font-size: 14px;
}
#dropdown-basic-button {
  background-color: rgba(11, 137, 92, 1);
  border: 1px solid rgba(11, 137, 92, 1);
}
.insight .nav-item .nav-link.active {
  color:rgba(51, 68, 153, 1);
  border-bottom:2px solid rgba(51, 68, 153, 1) !important;
}
.insight .nav-item .nav-link:not(.active) {
 color:rgba(69, 71, 69, 1);
 opacity: 0.7;
}
.model .nav-item .nav-link:not(.active) {
  opacity: 0.5;
 }
 .model .nav-item .nav-link:not(.active):hover {
  opacity: 0.5;
  color: black;
 }
 .mail .nav-item .nav-link:not(.active) {
  opacity: 0.5;
 }
 .mail .nav-item .nav-link:not(.active):hover {
  opacity: 0.5;
  color: black;
 }
 .sms .nav-item .nav-link:not(.active) {
  opacity: 0.5;
 }
 .sms .nav-item .nav-link:not(.active):hover {
  opacity: 0.5;
  color: black;
 }




