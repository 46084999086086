.tags {
  width: '32%';
  font-size: 10px;
  margin-top: -16%;
}

.tags1 {
  width: '32%';
  font-size: 10px;
  margin-top: -14%;
}

.buynow-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-product {
  font-size: 14px;
}

.overlay {
  position: absolute;
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* height: calc(70vh - 80px); */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

@media (max-width: 1390px) {
  .tags,
  .tags1 {
    width: 85%;
    justify-content: center;
    align-items: center;
    margin-right: 2.5%;
    margin-top: -20%;
  }
}

@media (max-width: 1200) {
  .tags,
  .tags1 {
    width: 80%;
    justify-content: center;
    align-items: center;
    bottom: 109px;
    margin-right: 2.5%;
    margin-top: -22%;
  }
}
