.fs-18 {
  size: 18px;
}

.fs-14 {
  color: #454549;
  size: 14px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}

/* .modal-backdrop {
    position: 'fixed';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

}

/* 
.modal-content {
    background-color: white;
    Padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
.loader_comp {
  position: absolute;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.image-container {
  position: relative;
  width: 80px;

  height: 80px;
  /* Fixed height */
}

.selected-radio {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-radio .form-check-input {
  position: relative;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  /* Default opacity for unselected state */
  transition: opacity 0.3s;
  /* Smooth transition for opacity change */
}

.selected-radio .form-check-input:checked {
  background-color: white;
  /* Set background to black when selected */
  opacity: 1;
  /* Full opacity when selected */
}

.selected-radio .form-check-input:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: black;
  /* Inner circle color when selected */
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.selected-radio .form-check-label {
  color: black;
  margin-left: 10px;
}
