.video_title_gallery {
  /* width: calc(100% - 23%); */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container_no_videos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.display-6 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 300;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.no-space-group .form-control {
  padding-left: 0;
  border: none;
}

.no-space-group .form-control::placeholder {
  margin-left: 0;
}
.review .nav-item .nav-link:not(.active) {
  opacity: 0.5;
}
.review .nav-item .nav-link:not(.active):hover {
  opacity: 0.5;
  color: black;
}
